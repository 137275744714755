<template>
  <AppLayout>
    <template v-slot:appContent>
      <section class="users-list-wrapper">
        <div class=" d-sm-flex justify-content-between align-items-center pt-2 pt-md-2 pt-xl-0 ">
          <h4 class="">Coupons/List</h4>
          <div>
            <router-link :to="can('coupon-create') ? {name:'appCouponCreate'} : '#'">
              <span :title="can('coupon-create') ? 'Create' : 'Create Forbidden'"
                    class="glow d-flex align-items-center">
                <i class='bx bx-plus bx-flip-vertical'></i>&nbsp;Create</span>
            </router-link>
          </div>
        </div>
        <div class="users-list-filter px-1 my-2 py-2 border rounded">
          <div>
            <div class="row align-items-center ">
              <div class="col-12 col-sm-12 col-md-2">
                <label>Show Per Page</label>
                <VueMultiselect :allowEmpty="false" v-model="selectedPagination" class="" :options="paginationOptions"
                                :close-on-select="true"
                                label="name" track-by="name" :show-labels="false"/>
              </div>
              <div class="col-12 col-sm-12 col-md-2">
                <label>Type</label>
                <VueMultiselect :allowEmpty="false" v-model="selectedType" class="" :options="typeOptions"
                                :close-on-select="true"
                                label="name" track-by="name" :show-labels="false"/>
              </div>
              <div class="col-12 col-sm-12 col-md-2">
                <label>Discount Type</label>
                <VueMultiselect :allowEmpty="false" v-model="selectedDiscountType" class=""
                                :options="discountTypeOptions"
                                :close-on-select="true"
                                label="name" track-by="name" :show-labels="false"/>
              </div>
              <div class="col-12 col-sm-12 col-md-2">
                <label>Apply On</label>
                <VueMultiselect :allowEmpty="false" v-model="selectedApplyOn" class="" :options="applyOnOptions"
                                :close-on-select="true"
                                label="name" track-by="name" :show-labels="false"/>
              </div>
              <div class="col-12 col-sm-12 col-md-2">
                <div class="form-group">
                  <label>Start Date</label>
                  <div class="d-flex align-items-center date-picker-wrapper">
                    <div class="d-flex align-items-center">
                      <i class='bx bx-calendar'></i>
                    </div>
                    <div class="w-100 pl-1 ">
                      <div class="w-100 ">
                        <DatePicker v-model="startDate" :update-on-input="true"
                                    :masks="{input: ['DD MMMM YYYY']}"
                                    :model-config="{ type: 'string', mask: 'YYYY-MM-DD'}">
                          <template v-slot="{ inputValue, inputEvents }">
                            <input class="form-control" :value="inputValue" v-on="inputEvents"/>
                          </template>
                        </DatePicker>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-2">
                <div class="form-group">
                  <label>End Date</label>
                  <div class="d-flex align-items-center date-picker-wrapper">
                    <div class="d-flex align-items-center">
                      <i class='bx bx-calendar'></i>
                    </div>
                    <div class="w-100 pl-1 ">
                      <div class="w-100 ">
                        <DatePicker v-model="endDate" :update-on-input="true"
                                    :masks="{input: ['DD MMMM YYYY']}"
                                    :model-config="{ type: 'string', mask: 'YYYY-MM-DD'}">
                          <template v-slot="{ inputValue, inputEvents }">
                            <input class="form-control" :value="inputValue" v-on="inputEvents"/>
                          </template>
                        </DatePicker>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row pt-2">
              <div class="col-12 col-sm-12 col-md-2">
                <label>Code/Name</label>
                <input v-model="getCouponsParams.name_code_query" class="form-control search-product-input-element"
                       type="text" placeholder="Search a coupon by code or name">
              </div>
              <div class="col-12 col-sm-12 col-md-2">
                <label>Discount From</label>
                <input v-model="getCouponsParams.discount[0]" class="form-control search-product-input-element"
                       type="text" placeholder="Search a coupon by discount from">
              </div>
              <div class="col-12 col-sm-12 col-md-2">
                <label>Discount To</label>
                <input v-model="getCouponsParams.discount[1]" class="form-control search-product-input-element"
                       type="text" placeholder="Search a coupon by discount to">
              </div>
              <div class="col-12 col-sm-12 col-md-2">
                <label>Activation From</label>
                <input v-model="getCouponsParams.activation_amount[0]" class="form-control search-product-input-element"
                       type="text" placeholder="Search a coupon by amount from">
              </div>
              <div class="col-12 col-sm-12 col-md-2">
                <label>Activation To</label>
                <input v-model="getCouponsParams.activation_amount[1]" class="form-control search-product-input-element"
                       type="text" placeholder="Search a coupon by amount to">
              </div>
              <div class="col-12 col-sm-6 col-md-2 pt-2">
                <button type="reset" class="btn btn-primary btn-block glow users-list-clear mb-0"
                        @click="applyCouponFilter(null)">Filter
                </button>
              </div>
            </div>

          </div>
        </div>
        <div class="users-list-table">
          <div class="card">
            <div class="card-body">
              <div class="table-responsive">
                <table id="users-list-datatable" class="table">
                  <thead>
                  <tr>
                    <th class="position-relative" :style="{width:'5%'}">SL</th>
                    <th class="position-relative" :style="{width:'20%'}">NAME, CODE & TYPE</th>
                    <th class="position-relative" :style="{width:'20%'}">APPLY, ACTIVATION & DISCOUNT</th>
                    <th class="position-relative" :style="{width:'20%'}">DURATION & LIMIT</th>
                    <th class="position-relative" :style="{width:'20%'}">DETAILS</th>
                    <th class="position-relative" :style="{width:'10%'}">STATUS</th>
                    <th :style="{width:'5%'}">ACTIONS</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(coupon, index) in coupons" :key="index"
                      :class="coupon.end_at !== null && couponExpiryDate(((coupon.end_at)) )
                      ? 'expire-date-bg' : ''">
                    <td>{{ index + 1 }}</td>
                    <td>
                      <span><strong class="text-uppercase">Name: </strong>{{ coupon.name }}</span><br>
                      <span><strong class="text-uppercase">Code: </strong>{{ coupon.code }}</span><br>
                      <span><strong class="text-uppercase">Type: </strong>{{ coupon.type }}</span>
                    </td>
                    <td>
                      <span><strong class="text-uppercase">Apply On: </strong>{{ coupon.apply_on }}</span><br>
                      <span><strong class="text-uppercase">Active At: </strong>${{ (coupon.activation_amount / 100).toFixed(2) }}</span><br>
                      <span v-if="coupon.apply_on === 'Flat Price'">
                        <strong class="text-uppercase">Grand Total: </strong>{{ `$${(coupon.discount / 100).toFixed(2)}` }}
                      </span>
                      <span v-else>
                        <strong class="text-uppercase">Discount: </strong>
                        {{coupon.discount_type === 'Fixed Amount' ? `$${(coupon.discount / 100).toFixed(2)}` : `${coupon.discount}%` }}
                      </span>
                    </td>
                    <td>
                      <span><strong class="text-uppercase">Start: </strong>{{ coupon.start_at }}</span><br>
                      <span><strong class="text-uppercase">End: </strong>{{ coupon.end_at ? coupon.end_at : 'No End Time' }}</span><br>
                      <span><strong class="text-uppercase">Total Coupon: </strong>{{ coupon.usage_per_coupon ?? 'Unlimited' }} Unit</span><br>
                      <span><strong class="text-uppercase">Usage Per User: </strong>{{ coupon.usage_per_user ?? 'Unlimited' }} Unit</span>
                    </td>
                    <td>
                      <span><strong class="text-uppercase">Internal Coupon: </strong>{{coupon.internal_coupon }}</span><br>
                      <span><strong class="text-uppercase">Website Visibility: </strong>{{coupon.website_visibility }}</span><br>
                      <span><strong class="text-uppercase">Same-day Restriction: </strong>{{coupon.same_day_restriction }}</span><br>
                      <span><strong class="text-uppercase">Weekend Restriction: </strong>{{coupon.weekend_restriction }}</span><br>
                      <span><strong class="text-uppercase">Holiday Restriction: </strong>{{coupon.holiday_restriction }}</span><br>
                      <span><strong class="text-uppercase">Creator: </strong>{{ coupon.creator?.full_name }}</span><br>
                      <span v-if="coupon.restricted_post_code"><strong class="text-uppercase">Redistricted Post Code: </strong>{{ coupon.restricted_post_code }}</span>
                    </td>
                    <td>
                      <button class="btn"
                              :disabled="!can('coupon-update')"
                              :title="can('coupon-update') ? `${coupon.status} | Click to update` : `${coupon.status} | ${unauthorized}`"
                              data-toggle="modal"
                              data-target="#couponStatusUpdateAlertModal"
                              @click="this.modelForUpdating = {modelId: coupon.id, existingData: {status: coupon.status}}">
                        <span class="badge"
                              :class="coupon.status === 'Active' ? 'badge-light-success' : 'badge-light-danger'">
                          {{ coupon.status }}
                        </span>
                      </button>
                    </td>
                    <td>
                      <div class="d-flex align-items-top justify-content-between">
                        <div>
                          <button
                              class="m-0 p-0 bg-transparent text-decoration-none cursor-pointer border-0 view-edit-delete-icon"
                              :disabled="!can('coupon-update')"
                              :title="can('coupon-update')? 'Update' : 'Update forbidden'"
                              :style="{height:'18px'}"
                              data-toggle="modal"
                              data-target="#couponEditModal"
                              @click="selectedCoupon = {}; selectedCoupon = coupon">
                            <i class='bx bx-edit'></i>
                          </button>
                        </div>
                        <div class="dropdown py-0 px-0 view-edit-delete-icon" :style="{height:'18px'}">
                          <span type="button" class="px-0 py-0" data-toggle="dropdown" aria-haspopup="true"
                                aria-expanded="false">
                            <i class='bx bx-dots-vertical-rounded h-100'></i>
                          </span>
                          <div class="dropdown-menu">
                            <router-link role="button" class="dropdown-item cursor-pointer"
                                         :autocorrect="{height:'18px'}"
                                         :to="can('coupon-usage-view-any') ? {name:'appCouponUsageView',params:{id:coupon.id}} : '#'"
                                         :title="can('coupon-usage-view-any') ? 'View' : 'View forbidden'">
                              View Usages
                            </router-link>

                            <button v-if="coupon.type ==='Assigned Only'" class="dropdown-item"
                                    :title="can('coupon-view') ? 'View' : 'View forbidden'"
                                    data-toggle="modal" data-target="#assignCouponModal"
                                    @click="assignCustomerCoupon(coupon.id)">Assign Customer
                            </button>
                            <button class="dropdown-item"
                                    :disabled="!can('coupon-delete')"
                                    :title="can('coupon-delete') ? 'Delete' : 'Delete forbidden'"
                                    data-toggle="modal" data-target="#couponDeleteAlertModal"
                                    @click="this.modelIdForDelete = coupon.id">Delete
                            </button>
                          
                            <router-link role="button" class="dropdown-item cursor-pointer"
                                         :autocorrect="{height:'18px'}"
                                         :to="{name:'appCouponEdm',params:{id:coupon.id}}"
                                         :title="can('coupon-usage-view-any') ? 'View' : 'View forbidden'">
                              Send EDM
                            </router-link>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <ListPagination position="right" :pagination-data="paginateLinks"
                              @getClickedPage="applyCouponFilter"/>
            </div>
          </div>
        </div>
        <CouponStatusUpdateAlertModal :model-for-updating="modelForUpdating" model-name="Coupon"
                                      modal-name="couponStatusUpdateAlertModal"
                                      @confirmModelUpdating="couponStatusUpdating"/>
        <CouponEditModal :coupon="selectedCoupon"/>
        <AssignCouponModal :coupon-id="selectedCouponId"/>
        <coupon-delete-alert-modal :model-id="modelIdForDelete" model-name="coupon" modal-name="couponDeleteAlertModal"
                                   @confirmModelDeletion="onConfirmModelDeletion"/>
      </section>
    </template>
  </AppLayout>
</template>

<script>
// components
import AppLayout from '@/layouts/backEnd/AppLayout.vue'
import ListPagination from "@/components/backEnd/pagination/ListPagination";
import CouponStatusUpdateAlertModal from '@/components/backEnd/modal/UpdateAlertModal';
import CouponEditModal from "@/views/backEnd/coupons/includes/CouponEditModal";
import AssignCouponModal from "@/views/backEnd/Marketing/Coupon/includes/AssignCouponModal";
import CouponDeleteAlertModal from "@/components/backEnd/modal/DeleteAlertModal";
// mixins
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Authorization from "@/components/backEnd/mixins/Authorization";
import Loader from "@/components/backEnd/mixins/Loader";
// core packages
import {mapActions, mapGetters} from "vuex";
// 3rd party packages
import {DatePicker} from "v-calendar";


export default {
  name: "CouponList",
  components: {
    AppLayout,
    CouponStatusUpdateAlertModal,
    CouponDeleteAlertModal,
    ListPagination,
    AssignCouponModal,
    DatePicker,
    CouponEditModal
  },
  mixins: [ShowToastMessage, Loader, Authorization],
  data() {
    return {
      unauthorized: 'You are unauthorized to perform this action.',
      getSettingsParams: {
        type: ['default'],
        key: ['pagination'],
      },

      getCouponsParams: {
        name_code_query: '',
        where_has_creator_query: '',
        type: '',
        discount_type: '',
        discount: [],
        activation_amount: [],
        apply_on: '',
        start_at: [],
        end_at: [],
        status: '',
        with_relation: ['creator'],
        // order_by_id: 'desc',
        paginate: 1,
        pagination: '',
        page: ''
      },


      selectedPagination: {
        value: '',
        name: 'Default'
      },

      selectedType: {
        value: '',
        name: 'Any'
      },

      selectedDiscountType: {
        value: '',
        name: 'Any'
      },

      selectedApplyOn: {
        value: '',
        name: 'Any'
      },

      selectedStatus: {
        value: '',
        name: 'Any'
      },

      modelForUpdating: {
        ModelId: '',
        existingData: {},
      },
      selectedCoupon: {},


      selectedCouponId: '',
      modelIdForDelete: '',
      startDate: '',
      endDate: '',
      expiryDate: false,

    }

  },
  watch: {
    selectedPagination: function (pagination) {
      this.getCouponsParams.pagination = pagination.value;
    },
    selectedType() {
      this.getCouponsParams.type = this.selectedType.value;
    },
    selectedDiscountType() {
      this.getCouponsParams.discount_type = this.selectedDiscountType.value;
    },
    selectedApplyOn() {
      this.getCouponsParams.apply_on = this.selectedApplyOn.value;
    },
    startDate() {
      this.getCouponsParams.start_at[0] = this.startDate !== '' ? this.startDate + ' 00:00:00' : '';
    },
    endDate() {
      this.getCouponsParams.end_at[0] = this.endDate !== '' ? this.endDate + ' 23:59:59' : '';
    }

  },
  computed: {
    ...mapGetters({
      paginateLinks: 'appCoupons/paginateLinks',
      coupons: 'appCoupons/coupons',
    }),
    paginationOptions() {
      return [
        {value: this.$store.getters['appSettings/settingDefaultPagination'].value, name: 'Default'},
        {value: 25, name: '25 Entries'},
        {value: 50, name: '50 Entries'},
        {value: 100, name: '100 Entries'}
      ];
    },

    typeOptions() {
      return [
        {
          value: '',
          name: 'Any'
        },
        {
          value: 0,
          name: 'Assigned Only'
        },
        {
          value: 1,
          name: 'Global'
        }
      ];
    },

    discountTypeOptions() {
      return [
        {
          value: '',
          name: 'Any'
        },
        {
          value: 0,
          name: 'Percentage'
        },
        {
          value: 1,
          name: 'Fixed amount'
        }
      ];
    },

    applyOnOptions() {
      return [
        {
          value: '',
          name: 'Any'
        },
        {
          value: 0,
          name: 'Subtotal'
        },
        {
          value: 1,
          name: 'Shipping Cost'
        }
      ];
    }
  },
  methods: {
    ...mapActions({
      getSettings: 'appSettings/getSettings',
      getCoupons: 'appCoupons/getCoupons',
      getCoupon: 'appCoupons/getCoupon',
      putCouponOnList: 'appCoupons/putCouponOnList',
      deleteCouponOnList: 'appCoupons/deleteCouponOnList',
    }),

    async couponStatusUpdating(couponStatusUpdating) {
      await this.couponStatusUpdatingOnList(couponStatusUpdating);
    },

    async couponStatusUpdatingOnList(couponStatusUpdating) {
      let dataObj = {
        id: couponStatusUpdating.modelForUpdating.modelId,
        data: {
          status: couponStatusUpdating.modelForUpdating.existingData.status === "Active" ? 0 : 1,
        }
      }
      this.putCouponOnList(dataObj).then(async (response) => {
        if (response.status === 200) {
          const toastObj = {
            message: "Coupon status updated",
            type: 'success'
          };
          this.showToastMessage(toastObj);
          return;
        }
        this.showToastMessage(response);
      });
    },

    async assignCustomerCoupon(couponId) {
      this.selectedCouponId = couponId;
      console.log(couponId)
    },

    async deleteSingleCouponOnLIst(id) {
      this.deleteCouponOnList(id).then(async (response) => {
        if (response.status === 200 || response.status === 204) {
          const toastObj = {
            message: "Coupon deleted successfully",
            type: 'success'
          };
          this.showToastMessage(toastObj);
          return;
        }
        this.showToastMessage(response);
      });
    },

    async onConfirmModelDeletion(confirmModelDeletion) {
      await this.deleteSingleCouponOnLIst(confirmModelDeletion.modelId);
    },

    async getCouponsList() {
      await this.getCoupons(this.getCouponsParams).then((response) => {
        if (response.status !== 200) {
          this.showToastMessage(response);
        }
      });
    },

    async getSettingList() {
      await this.getSettings(this.getSettingsParams);
    },

    async applyCouponFilter(pageNumber) {
      await this.loader(true);
      this.getCouponsParams.page = pageNumber;
      await this.getCouponsList();
      await this.loader(false);
    },
    couponExpiryDate(date) {
      return new Date(date) < new Date();
    }
  },
  async mounted() {

    await this.loader(true);
    await this.getSettingList();
    await this.getCouponsList();
    await this.loader(false);
    console.log(this.coupons);
  },

}
</script>

<style scoped>
.expire-date-bg {
  background-color: rgb(253, 242, 242);
}
</style>
